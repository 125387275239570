import React, { createContext, PropsWithChildren, useEffect, useMemo } from 'react';

export function EmbedContextProvider(props: PropsWithChildren) {
  const [embeddingUrl, setEmbeddingUrl] = React.useState<URL>(() => getUrl());

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (!validateOrigin(event.origin)) {
        return;
      }
      setEmbeddingUrl(new URL(event.data.url));
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const value = useMemo(() => ({
    url: embeddingUrl
  }), [embeddingUrl]);

  return (
    <EmbedContext.Provider value={value}>
      {props.children}
    </EmbedContext.Provider>
  )

}

type EmbedContextValue = {
  url: URL;
}

export const EmbedContext = createContext<EmbedContextValue>({
  url: getUrl()
});

function getUrl() {
  const currentUrl = new URL(window.location.href);
  if (window.self !== window.top) {
    const embedUrl = currentUrl.searchParams.get('embedUrl');
    if (embedUrl) {
      return new URL(embedUrl);
    }
  }
  return currentUrl;
}

const VALID_ORIGINS = [
  'https://bluej-development.webflow.io',
  'https://www.bluej.com'
];

function validateOrigin(origin: string) {
  return VALID_ORIGINS.includes(origin);
}
