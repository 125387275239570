import React from 'react';
import { PageTitle } from '../core/app/page-title';

export function NotFoundRoute() {
  return (
    <>
      <PageTitle title={'404'} />
      <div className="flex flex-col items-center justify-center">
        <div className="text-7xl font-bold">404</div>
        <div>Form not found</div>
      </div>
    </>
  )
}
