import { Api } from '../core/api/api';
import { PurchaseError } from './PurchaseError';

export class PurchaseApi extends Api {
  private _handlePurchase = this.createFetch<PurchasePayload, PurchaseResponse | PurchaseErrorResponse, void>(`/api/purchase`, 'POST');

  public async purchase(payload: PurchasePayload) {
    const result = await this._handlePurchase(payload);

    if (isPurchaseResponse(result)) {
      return result;
    }

    throw new PurchaseError(result);
  }
}

export type PurchaseFormData = {
  terms: string;
  email: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  taxTeamSize: string;
}

export type PurchasePayload = {
  recaptchaToken: string;
  region: Region;
  partnership?: string;
} & PurchaseFormData;

export type PurchaseResponse = {
  url: string;
}

export type PurchaseErrorResponse = {
  err: {
    message: string;
  }
}

function isPurchaseResponse(response: unknown): response is PurchaseResponse {
  return response !== null
    && response !== undefined
    && typeof response === 'object'
    && 'url' in response;
}

export type Region = 'us' | 'ca';
